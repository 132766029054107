import React from 'react';
import { render } from 'react-dom';
import MonacoEditor from 'react-monaco-editor';
import { hot } from 'react-hot-loader/root';
import dedent from 'dedent'
import type { editor } from 'monaco-editor';
import { ErrorDisplay } from './error';
import Popup from 'reactjs-popup';
import monaco from 'monaco-editor';
import { Cmd, Console } from './console';
import { inspect } from 'util';
import { utils } from 'ethers';

var compiler = new Worker(new URL('./compiler.ts', import.meta.url));

const hyvmAddresses = {
    'Polygon': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Ethereum': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Goerli': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Arbitrum': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Avalanche': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Optimism': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'BNB': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Fantom': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Aurora': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
    'Sepolia': '0xCB70efa43300Cd9B7eF4ed2087ceA7f7f6f3c195',
};

const chainIds = {
    '0x89': {
        name: 'Polygon',
        rpc: `https://polygon-rpc.com`,
    } as const,
    '0x1': {
        name: 'Ethereum',
        rpc: `https://cloudflare-eth.com`,
    } as const,
    '0x05': {
        name: 'Goerli',
        rpc: `https://eth-goerli.public.blastapi.io`,
    } as const,
    '0xa4b1': {
        name: 'Arbitrum',
        rpc: `https://rpc.ankr.com/arbitrum`,
    },
    '0xa86a': {
        name: 'Avalanche',
        rpc: `https://api.avax.network/ext/bc/C/rpc`,
    },
    '0xa': {
        name: 'Optimism',
        rpc: `https://mainnet.optimism.io`,
    },
    '0x38': {
        name: 'BNB',
        rpc: `https://bsc-dataseed.binance.org`,
    },
    '0xfa': {
        name: 'Fantom',
        rpc: `https://rpcapi.fantom.network`,
    },
    '0x4e454152': {
        name: 'Aurora',
        rpc: `https://mainnet.aurora.dev`,
    },
    '0xAA36A7': {
        name: 'Sepolia',
        rpc: `https://rpc.sepolia.org`,
    },
}

declare var compiler: Worker;
declare var ethereum: any;

interface NodeLocation {
    file: string;
    start: number;
    end: number;
}

interface State {
    globalError?: string | Error;
    status: 'loading' | 'error' | 'ok';
    popup?: React.ReactElement;
    cmds: Cmd[];
    code?: string;
    bytecode?: string;
    resultType?: string;
}
const App = hot(class extends React.Component<{}, State> {
    private editor: editor.ICodeEditor;
    private monaco: any;
    private timeout: any;
    private code: string;
    constructor(props) {
        super(props);
        this.state = { status: 'loading', cmds: [] }
        this.code = location.hash.substring(1) || dedent`
        // SPDX-License-Identifier: Unlicense
        pragma solidity ^0.8.15;

        // === add your imports here
        import "https://github.com/OpenZeppelin/openzeppelin-contracts/blob/master/contracts/token/ERC20/IERC20.sol";

        contract Main {

            function run() public view returns (string memory, uint256) {

                // === script whatever you want there:

                // this is AaveBTC (it has the same address on all chains):
                IERC20 token = IERC20(0x078f358208685046a11C85e8ad32895DED33A249);

                // get balance, and return it
                uint256 balance = token.balanceOf(msg.sender);

                return ("The balance is: ", balance);
            }
        }
`;
    }
    editorDidMount(editor: editor.ICodeEditor, monaco) {
        this.editor = editor;
        this.monaco = monaco;
        editor.focus();
        compiler.addEventListener('message', (e) => this.handleResult(e.data));
        this.compile(this.code);
    }

    onChange(newValue, e) {
        this.code = newValue;
        this.recompile();
    }


    recompile() {
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            globalError: null,
            status: 'loading',
            bytecode: null,
            resultType: null,
        });
        this.timeout = setTimeout(() => this.compile(this.code), 300);
    }


    setError(err: string | {
        errorCode: string;
        formattedMessage: string;
        message: string;
        severity: 'error' | string;
        type: string;
        sourceLocation: NodeLocation
    }[]) {
        if (typeof err === 'string') {
            return this.setState({
                ...this.state,
                status: 'error',
                globalError: err
            });
        }

        const thisFileErrors = err.filter(f => f.sourceLocation?.file === 'Playground.sol');
        if (!thisFileErrors.length && err.length) {
            return this.setError(`${err.length} errors in one your dependencies. The first one:
                ${err[0]?.formattedMessage ?? inspect(err)}`);
        }

        const markers: editor.IMarkerData[] = [];
        const decorations: editor.IModelDeltaDecoration[] = [];
        const model = this.editor.getModel();
        const computePos = (loc: NodeLocation) => {
            let s = (loc.start ?? 0);
            while (/[\s;\r\n]/.test(this.code[s] ?? 'x')) {
                s++;
            }
            let e = (loc.end ?? this.code.length);
            while (s < e && /[\s;\r\n]/.test(this.code[e] ?? 'x')) {
                e--;
            }
            return {
                start: model.getPositionAt(s),
                end: model.getPositionAt(e),
            }
        }
        const addMarker = (loc: NodeLocation, message: string, severity: monaco.MarkerSeverity) => {
            const { start, end } = computePos(loc);
            markers.push({
                severity,
                message: message,
                startLineNumber: start.lineNumber,
                startColumn: start.column,
                endLineNumber: end.lineNumber,
                endColumn: end.column,
            })
        };
        const addDecoration = (loc: NodeLocation, classname: string, message: string) => {
            const { start, end } = computePos(loc);

            const range: monaco.Range = new this.monaco.Range(start.lineNumber
                , start.column
                , end.lineNumber
                , end.column);
            decorations.push({
                range,
                options: {
                    hoverMessage: message && {
                        value: message,
                    },
                    glyphMarginClassName: 'icon ' + classname,
                },
            })
        }
        // create results
        for (const e of thisFileErrors) {
            addMarker(e.sourceLocation, e.message, e.severity === 'warning'
                ? this.monaco.MarkerSeverity.Warning
                : this.monaco.MarkerSeverity.Error);
            // addDecoration(e.sourceLocation, e.severity, e.message);
        }
        this.monaco.editor.setModelMarkers(model, "playground", markers);
        this.editor.deltaDecorations([], decorations);
        this.setState({
            ...this.state,
            globalError: null,
            status: thisFileErrors.some(x => x.severity === 'error') ? 'error' : 'ok',
        });
    }

    compile(code: string) {
        this.setState({
            ...this.state,
            status: 'loading',
            code,
            globalError: null,
            bytecode: null,
            resultType: null,
        });
        compiler.postMessage([code]);
    }

    handleResult({ bytecode, resultType, error, warnings }) {
        if (error) {
            return this.setError(error);
        }
        this.setError(warnings ?? []);
        this.setState({
            ...this.state,
            status: 'ok',
            globalError: null,
            bytecode,
            resultType,
        });

    }

    private pushError(command: string, err: string) {
        this.setState({
            ...this.state,
            cmds: [...this.state.cmds, {
                command,
                resultType: 'error',
                result: err,
            }]
        })
    }

    performAction = async (commandType: 'send' | 'call', act: (from: string, to: string, value: string, data: string) => Promise<string>) => {

        if (!this.state.bytecode) {
            return;
        }
        const data = '0x' + this.state.bytecode;
        let command = 'cast call';
        try {
            if (typeof ethereum === 'undefined') {
                return this.pushError('cast call', 'Metamask not detected');
            }
            const chainId = await ethereum.request({ method: 'eth_chainId' });
            console.log(chainId);
            const toNet = chainIds[chainId];
            const to = hyvmAddresses[toNet?.name];
            command = `cast call ${to ?? '<unkown contract>'} --rpc-url  ${toNet?.rpc ?? '<unkown network>'} ${data}`;
            if (!toNet) {
                this.pushError(command, `HyVM playground is only deployed on ${Object.keys(hyvmAddresses).join(', ')} (chain ${chainId} not supported) 👉 please switch Metamask to one of those.`);
                return;
            }

            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            const from = ethereum.selectedAddress ?? accounts[0];
            const result = await act(from, to, '0x0', data);

            let decodedDump: any;

            try {
                const decoded = new utils.AbiCoder().decode(this.state.resultType.split(','), result, true);
                decodedDump = decoded.map(e => typeof e === 'string' ? JSON.stringify(e) : e.toString()).join(', ')
            } catch (e) {
                decodedDump = <span className='warn'>💥 Failed to decode:  {inspect(e)}</span>
            }

            this.setState({
                ...this.state,
                cmds: [...this.state.cmds, {
                    command,
                    resultType: 'success',
                    result: <span style={{ display: 'inline-block' }}>
                        <div>
                            <span className='resultLabel'>Raw result: </span>
                            {result}
                        </div>

                        <div>
                            <span className='resultLabel'>Decoded result: </span>
                            <pre style={{ display: 'inline-block' }}>{decodedDump}</pre>
                        </div>
                    </span>,
                }]
            })
        } catch (e) {
            this.pushError(command, inspect(e));
        }
    }

    executeReadonly = async () => {
        if (!this.state.bytecode) {
            return;
        }
        await this.performAction('call'
            , async (from, to, value, data) => {
                return await ethereum.request({
                    method: 'eth_call',
                    params: [{
                        // nonce: '0x00', // ignored by MetaMask
                        // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
                        // gas: '0x2710', // customizable by user during MetaMask confirmation.
                        to,
                        from,
                        value,
                        data,
                    }, 'latest'],
                });
            })
        // ethereum.send({
        //     // method: 'eth_sendRawTransaction',
        //     method: 'eth_call',
        //     params: ['0x' + this.state.bytecode],
        // }, function (...args) { console.log(args) })
    };
    signAndExecute = async () => {
        if (!this.state.bytecode) {
            return;
        }
        await this.performAction('send'
            , async (from, to, value, data) => {
                return await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        // nonce: '0x00', // ignored by MetaMask
                        // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
                        // gas: '0x2710', // customizable by user during MetaMask confirmation.
                        to,
                        from,
                        value,
                        data,
                    }],
                });
            });
    }

    closeModal = () => {
        this.setState({
            ...this.state,
            popup: null,
        });
    }
    render() {
        const options = {
            selectOnLineNumbers: true,
            glyphMargin: true,
        };
        return (
            <div>
                <div className="header">
                    <a href="https://github.com/oguimbal/hyvm">HyVM</a> Playground&nbsp;
                </div>
                <MonacoEditor
                    width="100%"
                    height="75vh"
                    language="sol"
                    theme="vs-dark"
                    value={this.code}
                    options={options}
                    onChange={this.onChange.bind(this)}
                    editorDidMount={this.editorDidMount.bind(this)}
                />
                <Console commands={this.state.cmds} />

                {this.renderButtons()}

                {
                    !this.state.popup ? null
                        : <Popup open={true}
                            closeOnDocumentClick
                            onClose={this.closeModal} position="right center">
                            {this.state.popup}
                        </Popup>
                }

            </div>
        );
    }

    copySharingLink = () => {
        const link = `https://oguimbal.github.io/hyvm-live-playground#${encodeURIComponent(this.state.code ?? '')}`
        try {

            navigator.clipboard.writeText(link);


            this.setState({
                ...this.state,
                cmds: [...this.state.cmds, {
                    command: 'share',
                    resultType: 'success',
                    result: `Sharing link copied to clipboard: ${link}`,
                }]
            })
        } catch (e) {
            this.pushError('share', `Failed to copy sharing link to clipboard: ${link}`);
        }
    }

    renderButtons() {
        if (this.state.globalError) {
            return <ErrorDisplay error={this.state.globalError} />;
        }
        const loadInd = this.state.status === 'loading' ? '⌛' : null;
        return <div className={'buttons ' + this.state.status} >
            <div onClick={this.executeReadonly}>
                {loadInd ?? '📖'} Static call (readonly)
            </div>
            <div onClick={this.signAndExecute}>
                {loadInd ?? '⚡'} Sign and execute
            </div>
            <div onClick={this.copySharingLink}>
                Copy sharing link
            </div>
        </div >
    }
});

render(
    <App />,
    document.getElementById('root')
);
