
import React, { useEffect, useRef } from 'react'

// @ts-ignore
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export interface Cmd {
    command: string;
    result: string | React.ReactElement;
    resultType: 'success' | 'error';
}

export function Console(this: void, { commands }: { commands: Cmd[] }) {

    const wrapperRef = useRef<HTMLDivElement>(null);


    const scrollToBottom = () => {
        setTimeout(() => {
            wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
        })
    };


    useEffect(scrollToBottom, [commands]);


    return (
        <div
            className='wrapper'
            style={{
                overflowY: isIE11 ? "scroll" : "auto",
            }}
            ref={wrapperRef}
        >
            <div>
                {commands
                    .map((line, key) =>
                        <div
                            key={key}>
                            <pre className='line command'>$ {line.command}</pre>
                            <pre className={'line ' + line.resultType}>{line.result}</pre>
                        </div>
                    )}
                <pre className='line'>$</pre>
            </div>
        </div>
    );
}